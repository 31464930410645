"use strict";

$(document).ready(function() {
  initializeDonationForm();
  prepareDonationForm();

  $("#new_subscription").submit(function (e) {
    e.preventDefault();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);
    let policy = $('#policy_checkbox').prop('checked');
    if (!$('#policy_checkbox').prop('checked')) {
      swal_popup('warning', 'Необходимо принять условия публичной оферты', '');
    }
    else {
      $.ajax({
        url: url,
        type: "POST",
        dataType: "json",
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (data) {
          if (data.saved) {
            swal_popup('success', 'Запрос успешно отправлен', '');
            $("#new_subscription")[0].reset();
            window.popups.close();
          }
          else {
            let html = '<ul class="about-page__list">'
            $(data.errors).each(function() {
              html += `<li>${this}</li>`
            });
            html += '</ul>'
            swal_popup('warning', 'Исправьте следующие ошибки:', html);
          }
        },
        error: function (data) {
          swal_popup('warning', 'Произошла непредвиденная ошибка, попробуйте позже', html);
        }
      });
      // executeRecaptchaForNewSubscription();
    }
  });

  $("#new_request").submit(function (e) {
    e.preventDefault();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);

    $.ajax({
      url: url,
      type: "POST",
      dataType: "json",
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      success: function (data) {
        if (data.saved) {
          swal_popup('success', 'Запрос успешно отправлен', '');
          $("#new_request")[0].reset();
        }
        else {
          let html = '<ul class="about-page__list">'
          $(data.errors).each(function() {
            html += `<li>${this}</li>`
          });
          html += '</ul>'
          swal_popup('warning', 'Исправьте следующие ошибки:', html);
        }
      },
      error: function (data) {
        swal_popup('warning', 'Исправьте следующие ошибки:', html);
      }
    });
    // executeRecaptchaForSaveHelpRequest();
  });

  $("#new_feedback").submit(function (e) {
    e.preventDefault();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);

    $.ajax({
      url: url,
      type: "POST",
      dataType: "json",
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      success: function (data) {
        if (data.saved) {
          swal_popup('success', 'Запрос успешно отправлен', '');
          $("#new_feedback")[0].reset();
          window.popups.close();
        }
        else {
          let html = '<ul class="about-page__list">'
          $(data.errors).each(function() {
            html += `<li>${this}</li>`
          });
          html += '</ul>'
          swal_popup('warning', 'Исправьте следующие ошибки:', html);
        }
      },
      error: function (data) {
        swal_popup('warning', 'Исправьте следующие ошибки:', html);
      }
    });
    // executeRecaptchaForSaveFeedback();
  });

  checkCookies();

  // Burger menu open & close
  let burgerIcon = document.querySelector('.burger');
  let headerMenu = document.querySelector('#navigation');

  if (burgerIcon) {
    burgerIcon.addEventListener('click', function () {
      headerMenu.style.backgroundColor = 'white';
      burgerIcon.classList.toggle('_active');
      headerMenu.classList.toggle('_active');

      if (headerMenu.classList.contains('_active')) {
        bodyScrollLock.disableBodyScroll(headerMenu);
        headerMenu.style.backgroundColor = 'white'
      } else {
        bodyScrollLock.enableBodyScroll(headerMenu);
        headerMenu.style.backgroundColor = 'white';
      }
    });
  }

  $(document).ready(function () {
    $(window).bind('scroll', function () {
      if ($(window).scrollTop() > 1) {
        $('#navigation_bar').addClass('scrollfix');
        burgerIcon.style.backgroundColor = 'white'
      } else {
        $('#navigation_bar').removeClass('scrollfix');
      }
    });
  });
  // Help popup open & close
  window.popups = new HystModal({
    linkAttributeName: 'data-hystmodal'
  });

  // Dynamic relocation of elements on resize
  let dynamicAdaptElements = new DynamicAdapt('min');
  dynamicAdaptElements.init();

  // Promo slider
  let promoSlider = new Swiper('.promo__slider', {
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    },
    // autoHeight: true,
    height: 450,
    spaceBetween: 30,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  });

  // Help slider
  let helpSlider = new Swiper('.help-block__slider .swiper', {
    loop: true,
    perMove: 1,
    slidesPerView: 2,
    spaceBetween: 16,
    navigation: {
      nextEl: '.help-block__next',
      prevEl: '.help-block__prev'
    },
    breakpoints: {
      768: {
        slidesPerView: 3
      },
      1024: {
        slidesPerView: 4
      },
      1440: {
        slidesPerView: 5,
        spaceBetween: 25
      }
    }
  });

  // Child-page slider
  let sliderThumbs = new Swiper('.slider__thumbs', {
    spaceBetween: 16,
    slidesPerView: 3.6,
    freeMode: true,
    watchSlidesProgress: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      1440: {
        slidesPerView: 3
      }
    }
  });

  // Copy text(fundraiser)
  global.copytext = function copytext(el) {
    let $tmp = $("<input>");
    $("body").append($tmp);
    $tmp.val($(el).text()).select();
    document.execCommand("copy");
    $tmp.remove();
    $('#modal_fand').removeClass('hystmodal--active');
    $('.hystmodal__shadow').removeClass('hystmodal__shadow--show');
  }


  let sliderMain = new Swiper('.slider__main', {
    spaceBetween: 10,
    thumbs: {
      swiper: sliderThumbs
    }
  });

  $("blockquote").prepend(
    '<svg class="quote-icon" height="32" width="52"><use xlink:href="#quote"></use></svg>'
  );

  $('a.donation-page-link').on('click', function (e) {
    if ($.cookie('direct_donate_form') && $.cookie('payment_method') && $.cookie('payment_method').split(',').length === 1) {
      directDonation(null);
    } else {
      window.location.href = $(this).data('link');
    }
  });
});

function cloudPaymentPay(public_key, email, payment_id, desc, amount, currency) {
  let widget = new cp.CloudPayments();
  widget.pay('auth',
    {
      publicId: public_key,
      description: desc,
      amount: amount,
      currency: currency,
      accountId: email,
      invoiceId: payment_id,
      email: email,
      skin: "mini",
      autoClose: 3,
      data: {
        myProp: ''
      }
    },
    {
      onSuccess: function (options) {
        window.location.href = '/success-pay';
      },
      onFail: function (reason, options) {
        window.location.href = '/error_pay';
      }
    }
  )
}

// Embed youtube videos the right way
function embedYoutube() {
  let youtubeContainers = document.querySelectorAll('.embed-youtube');

  let _loop = function _loop(i) {
    let container = youtubeContainers[i];
    let imageSource = 'https://img.youtube.com/vi/' + container.dataset.videoId + '/sddefault.jpg';
    let image = new Image();
    image.src = imageSource;
    image.addEventListener('load', function () {
      container.appendChild(image);
    });
    container.addEventListener('click', function () {
      let iframe = document.createElement('iframe');
      iframe.setAttribute('frameborder', '0');
      iframe.setAttribute('allowfullscreen', '');
      iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
      iframe.setAttribute('src', 'https://www.youtube.com/embed/' + this.dataset.videoId + '?rel=0&showinfo=0&autoplay=1');
      this.innerHTML = '';
      this.appendChild(iframe);
    });
  };

  for (let i = 0; i < youtubeContainers.length; i++) {
    _loop(i);
  }
}

// Cookies handling
function checkCookies() {
  let use_cookies = $.cookie('use_cookies');
  if (use_cookies != 'true') {
    let cookiesPopup = document.getElementById('site_cookies');

    if (cookiesPopup) {
      let showCoolies = function showCoolies() {
        scrollpos = window.scrollY;

        if (scrollpos >= 50) {
          cookiesPopup.classList.add('_shown');
        }
      };

      let scrollpos = window.scrollY;
      window.addEventListener('scroll', showCoolies);
      let cookieDismiss = document.getElementById('cookies_dismiss');
      cookieDismiss.addEventListener('click', function () {
        $.cookie('use_cookies', true);

        cookiesPopup.classList.remove('_shown');
        window.removeEventListener('scroll', showCoolies);
      });
    }
  }
}

// Расчет итоговой суммы пожертвования
global.calc_summary = function calc_summary(form) {
  $(form).find('.total_donation_sum').html('0');
  let count = 1;
  $(form).find('.kid-checkbox').each(function() {
    if ($(this).is(':checked')) {
      count = count + 1;
    }
  })
  let val = $(form).find('.payment_amount').val();
  if (val) {
    $(form).find('.total_donation_sum').html(parseInt(val) * count);
  }
}

// Проверка корректности ввода почты
global.emailCheck = function emailCheck (email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

global.directDonation = function directDonation(selected) {
  let formData = new FormData();

  let url = $(selected).data('url');
  let amount = $(selected).data('amount');
  let tag = $(selected).data('tag');
  let fundraiser = $(selected).data('fundraiser');
  let payment_method = $(selected).data('payment-method');
  let fundraisingId = selected ? ($(selected).data('fundraising') || []) : [];
  let authenticityToken = $(selected).data('token');

  formData.append('authenticity_token', authenticityToken);
  formData.append('direct_donate_form', true);
  formData.append('donation[fundraising_id]', fundraisingId);
  formData.append('donation[amount]', amount);
  formData.append('donation[tag]', tag);
  formData.append('donation[fundraiser]', fundraiser);
  formData.append('donation[payment_method]', payment_method);
  callDonateForm(url, formData);
}

// Открытие модалки оплаты
global.openDonationModal = function openDonationModal(selected) {
  let url = $(selected).data('url');

  $.ajax({
    url: url,
    type: "GET",
    dataType: "html",
    success: function (data) {
      $('#donation-modal .donate-modal__body').html(data);
      popups.open('#donation-modal');
      initializeDonationForm();
      prepareDonationForm();
    }
  });
}

global.prepareDonationForm = function prepareDonationForm() {
  let tag = $.cookie('FAND');
  let fundraiser = $.cookie('fundraiser');
  let amount = $.cookie('amount');

  $('.donate-form #donation_tag').val(tag);
  $('.donate-form #donation_fundraiser').val(fundraiser);
  $('.donate-form .total-donation-amount').html(amount);
  $('.donate-form .donation-amount').html(amount);
  $('.donate-form .payment_amount').val(amount);

  if ($.cookie('direct_donate_form')) {
    let payment_methods = $.cookie('payment_method') ? $.cookie('payment_method').split(',') : [];

    $('.donate-form .donation-modal-tabs').addClass('hidden');
    $('.donate-form .cloudpayments-blocks').addClass('hidden');
    $('.donate-form .more-children').addClass('hidden');
    $('.donate-form .online__step-third').addClass('hidden');
    $('.donate-form .online__step-fourth').addClass('hidden');
    $('.donate-form .donation-text').addClass('hidden');
    $('.donate-form .donation-footer').addClass('hidden');
    $('.donate-form .form-footer .form-footer__total').addClass('hidden');
    $('.donate-form .form-footer').css('justify-content', 'center');
    $('.donate-form .public_offer_container').addClass('hidden');
    $('.donate-form .subs_container').addClass('hidden');
    if ($.cookie('payment_method').split(',').length < 2) {
      $('.donate-form .online__step-first').addClass('hidden');
      $('.donate-form .online__options').addClass('hidden');
      $('.online__step-second .number').addClass('hidden').before('<text style="width: 40%;">Введите сумму пожертвования</text>')
      $('#payment_policy_checkbox').click();
    } else {
      $('.online__step-second .number').after('<text style="width: 50%;">Введите сумму пожертвования</text>');
    }
    $(`.donate-form #donation_payment_method_${payment_methods[0]}`).click();
    let all_payment_methods = ['sberbank', 'sbp', 'bank_card', 'yandex_money', 'qiwi', 'tinkoff_bank', 'alfabank', 'mobile', 'b2b_sberbank', 'cloudpayments'];
    let hidden_methods = all_payment_methods.filter(n => !payment_methods.includes(n))
    $(hidden_methods).each(function() {
      $(`.${this}-payment`).addClass('hidden');
    });
  }
}

global.initializeDonationForm = function initializeDonationForm() {
  $('.more-children__link').on('click', function() {
    $('.swiper.more-children__slider .swiper-slide').css('display', 'block');
  });

  // Обработка формы пожертвования
  $(".donate-form").on('submit', async function(e) {
    event.preventDefault();
    // let donate_recaptcha = await executeRecaptchaForDonateAsync();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);
    let errors = false;
    let email = formData.get('donation[email]')

    if (!$.cookie('direct_donate_form')) {
      let payment_policy = $('#payment_policy_checkbox').prop('checked') || $('#payment_policy_checkbox_regular').prop('checked');
      if (!errors && !payment_policy) {
        swal_popup('warning', 'Необходимо принять условия публичной оферты', '');
        errors = true
      }

      if (formData.get('donation[amount]') == '') {
        swal_popup('warning', 'Введите сумму пожертвования', '');
        errors = true
      } else {
        if (formData.get('donation[payment_method]') == 'mobile') {
          if (formData.get('donation[phone]') === '') {
            swal_popup('warning', 'Ошибка при вводе номера телефона', '');
            errors = true
          }
        } else {
          if (email == '' || emailCheck(email) == null) {
            swal_popup('warning', 'Введите свой e-mail', '');
            errors = true
          }
        }
      }
    }

    if (!errors) {
      let yandex_key = $('#metrika-keys').data('yandex');
      let vk_key = $('#metrika-keys').data('vk');

      _tmr.push({ type: 'reachGoal', id: vk_key, value: 1, goal: 'donation'});
      ym(yandex_key,'reachGoal','pomosh');

      // formData.append('g-recaptcha-response-data[donate]', donate_recaptcha);
      callDonateForm(url, formData);
    }
  });

  // Изменение суммы пожертвования
  $('.payment_amount').on('change', function() {
    let form = $(this).closest('form')
    form.find('.kid-donate-sum').html($(this).val() || 0);
    calc_summary(form);
  });
  $('.payment_amount').on('keyup', function() {
    let form = $(this).closest('form')
    form.find('.kid-donate-sum').html($(this).val() || 0);
    calc_summary(form);
  });

  // Выбор ребенка на странице пожертвования
  $('.kid-checkbox').on('change', function() {
    let form = $(this).closest('form');
    calc_summary(form);
  });

  $(".phone_mask").mask("+9(999)999-99-99");

  // Donation slider
  let moreChildrenSlider = document.querySelector('.more-children__slider');
  let donationSlider = new Swiper('.more-children__slider', {
    autoplay: false,
    perMove: 1,
    slidesPerView: 1.1,
    spaceBetween: 16,
    navigation: {
      nextEl: '.more-children__next',
      prevEl: '.more-children__prev'
    },
    breakpoints: {
      768: {
        slidesPerView: 2.2
      }
    }
  });

  // Destroy donation slider on desktop
  function destroyDonationSlider() {
    let desktopRes = window.matchMedia('(min-width: 1024px)');

    if (desktopRes.matches && moreChildrenSlider) {
      donationSlider.destroy();
    }
  }
  destroyDonationSlider();

  // Payment tabs logic
  let paymentTabs = document.querySelectorAll('.tabs-block__link');
  paymentTabs.forEach(function (tab) {
    tab.addEventListener('click', tabClicks);
  });
  function tabClicks(tabClickEvent) {
    for (let i = 0; i < paymentTabs.length; i++) {
      paymentTabs[i].classList.remove('_active');
    }

    let clickedTab = tabClickEvent.currentTarget;
    let policy = document.querySelector('#online-and-abroad');
    if (policy) {
      if (clickedTab.classList.contains("online-abroad")) {
        policy.classList.add('hidden');
      }
      else {
        policy.classList.remove('hidden');
      }
    }
    clickedTab.classList.add('_active');
    tabClickEvent.preventDefault();
    let tabPanels = document.querySelectorAll('.tabs-block__panel');

    for (let _i = 0; _i < tabPanels.length; _i++) {
      tabPanels[_i].classList.remove('_active');
    }

    let anchorReference = tabClickEvent.target;
    let activePanelId = anchorReference.getAttribute('href');
    let activePanel = document.querySelector(activePanelId);
    activePanel.classList.add('_active');
  }

  embedYoutube();
}

global.callDonateForm = function callDonateForm(url, formData) {
  $.ajax({
    url: url,
    type: "POST",
    dataType: "json",
    data: formData,
    cache: false,
    contentType: false,
    processData: false,
    success: function (data) {
      if (data.saved) {
        try {
          VK.Goal('lead');
        } catch {
          // ToDo remove this later
          // just ignore
        }
        if (data.payment_gateway == 'uniteller') {
          $('#unitellerPay input[name=Order_IDP]').val(data.payment_id);
          $('#unitellerPay input[name=Subtotal_P]').val(data.amount);
          $('#unitellerPay input[name=Signature]').val(data.signature);
          $('#unitellerPay input[name=Email]').val(data.email);
          $('#unitellerPay input[name=Phone]').val(data.phone);
          $('#unitellerPay input[name=IsRecurrentStart]').val(data.recurrent);
          $("#unitellerPay").submit();
        } else if (data.payment_gateway == 'yookassa') {
          window.location.href = data.payment_url;
        } else if (data.payment_gateway == 'mixplat') {
          window.location.href = data.payment_url;
        } else if (data.payment_gateway == 'cloudpayments') {
          cloudPaymentPay(data.public_key, data.email, data.payment_id, data.description, data.amount, data.currency)
        } else if (data.payment_gateway === 'qiwi_gate') {
          window.location.href = data.payment_url;
        } else {
          swal_popup('error', 'Произошла ошибка, попробуйте позже', '');
        }
      } else {
        let html = '<ul class="about-page__list">'
        $(data.errors).each(function() {
          html += `<li>${this}</li>`
        });
        html += '</ul>'
        swal_popup('warning', 'Исправьте следующие ошибки:', html);

      }
    },
    error: function (data) {
      swal_popup('error', 'Произошла ошибка, попробуйте позже', '');
    }
  });
}

function swal_popup(icon, title, body) {
  Swal.fire({
    icon: icon,
    title: title,
    html: body,
    showConfirmButton: false,
    showCloseButton: true
  });
}